

<template>
  <div class="nav">
    <div class="layout-nav">
      <div class="nav-list dis-flex space-between margin-auto-0">
        <div class="nav-list-l dis-flex">
          <div class="nav-list-ls"></div>
          <ul class="head-lists dis-flex">
            <li
              v-for="(item, index) in courseData[curactive]"
              :key="index"
              :class="
                navidx == index ? 'head-navbaac' : 'head-nonav'
              "
              @click="switCourse(item, index)"
            >
              {{ item.name }}
            </li>
            <!-- <li
                  class="head-navba"
              >
              </li>
              <li
                  class="head-navba"
              >

              </li>
              <li
                  class="head-navba"
              >
              </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "secnav",
  props: ["secnav"],
  data() {
    return {
      // 退出按钮
      exitBtn: false,
      headimg: "",
      logo: "",
      usernames: "",
      casenumber: "",
      courseData: [
        [{ id: "0", name: "课程管理" }],
        [{ id: "1", name: "班级管理" }],
        [
          { id: "2", name: "作品管理", url: "/course" },
          { id: "3", name: "相册管理", url: "/photoList" },
          { id: "4", name: "成绩管理", url: "" },
        ],
      ],

      curactive: 0,
      navidx: 0,
    };
  },
  created() {
    console.log(this.$route.path);
      this.navidx= 0

    if (this.$route.path == "/classBa") {
      this.curactive = 1;
    } else if (this.$route.path == "/classcheck") {
      this.curactive = 1;
    } else if (this.$route.path == "/classDetail") {
      this.curactive = 1;
    } else if (this.$route.path == "/tolead") {
      this.curactive = 1;
    } else if (this.$route.path == "/index") {
      this.curactive = 0;
    } else if (this.$route.path == "/course") {
      this.curactive = 2;
    } else if (this.$route.path == "/works") {
      this.curactive = 2;
    } else if (this.$route.path == "/jobdetail") {
      this.curactive = 2;
    } else if (this.$route.path == "/upworks") {
      this.curactive = 2;
    } else if (this.$route.path == "/photoList") {
      this.curactive = 2;
      this.navidx= 1
    } else if (this.$route.path == "/photodetali") {
      this.curactive = 2;
      this.navidx= 1

    } else if (this.$route.path == "/upphoto") {
      this.curactive = 2;
      this.navidx= 1

    }
  },
  methods: {
    // navba切换
    switCourse(val, index) {
      console.log(val,this.$route.path);

      // this.courseData_id = id
      // this.cur_date = id
      // this.curactive = index;
      if (val.url) {
        if (this.$route.path != val.url) {
          this.$router.push({
            path: val.url,
          });
        }
      }
    },
    // 返回首页
    toIndex() {
      // console.log(this.$route.path)
      if (this.$route.path === "/index") {
        this.$router.go(0);
      } else {
        this.$router.push("/index");
      }
    },
    // 点击头像
    clickHead() {
      this.exitBtn = !this.exitBtn ? true : false;
    },
    // 退出登录
    clickEcit() {
      console.log(111111);
      console.log(111111);
      this.exitBtn = false;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #FFF1F1 0%, #FDF9E9 100%);
  opacity: 0.5;
  /*margin-top: -20px;*/
}

.nav_title {
  width: 87px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin-left: 500px;
  background-color: #FFFFFF;
}

.center {
  width: 65%;
  /*width: 80%;*/
  margin: 0 auto;
  min-height: 300px;
}

.class_nav {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #FF8E42;
}

.class_nav span:first-child {
  color: #999999;
}

.course-nav {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin: 20px 0;
  /*background-color: #4A80F7;*/
}

.course-nav-item {
  color: #999999;
  font-size: 16px;
  margin-right: 20px;
}

.sousuo {
  width: 85px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  color: #FFFFFF;
  text-align: center;
  background: #FF8E42;
  border-radius: 4px;
}

.class_list {
  width: 100%;
  display: flex;
  min-height: 500px;
  flex-wrap: wrap;
  margin: 0 auto;
  /*justify-content: space-between;*/
  /*background-color: #F22E2E;*/

}

.class_item {
  width: 270px;
  height: 250px;
  background: #FFFFFF;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 8px;
  /*align-self: ;*/
  margin: 20px 10px;
}


.item_img {
  /*width: 270px;*/
  height: 152px;
}

.item_img > img {
  width: 100%;
  height: 100%;
}

.class_item h3 {
  min-width: 151px;
  height: 17px;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  padding: 5px 0 0 15px;
  margin-bottom: 20px;
}

.class_item p {
  text-align: right;
  margin-left: 14px;
  margin-top: 8px;
  color: #999999;
  font-size: 15px;
}

.upzp {
  width: 80px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 15px;
  margin-top: 5px;
  background: #FF8E42;
  border-radius: 4px;
}

.wushuju {
  width: 235px;
  height: 600px;
  margin: 100px auto;
}

.wushuju > img {
  width: 235px;
  height: 196px;
}


.layout-nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-top: -4px;
}

.nav-list {
  width: 80%;
  height: 60px;
}

.nav-list-l {
  width: 586px;
  /*height: 24px;*/
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: #6094FF;
}

.nav-list-ls {
  width: 86px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  /*margin-top: 5px;*/
  color: #6094FF;
}

.nav-list-ls > img {
  width: 86px;
  height: 24px;
  margin-top: 16px;
}
.head-nonav{
    width: 84px;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  color: #111111;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
}
.head-navbaac {
  width: 84px;
  height: 60px;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  color: #111111;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border-bottom: 2px solid #FF7800;
}

.head-navba {
  width: 84px;
  height: 60px;
  margin: 0 10px;
}

.head {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
}

.head > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.head-name {
  min-width: 42px;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

</style>