<template>
  <div>
    <!-- <div class="nav">
      <div class="layout-nav">
        <div class="nav-list dis-flex space-between margin-auto-0">
          <div class="nav-list-l dis-flex">
            <div class="nav-list-ls">
            </div>
            <ul class="head-lists dis-flex">
              <li
                  class="head-navba"
              >
              </li>
              <li
                  class="head-navba"
              >
              </li>
              <li
                  class="head-navba"
              >
              </li>
              <li
                  class="head-navbaac"
              >相册管理
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div> -->
    <secnav></secnav>
    <div class="center">
      <div class="class_nav">
        <span>当前位置：</span> <span>相册管理</span>
      </div>
      <div class="course-nav dis-flex">
        <div class="course-nav-item dis-flex">
          <h3>班级：</h3>
          <div>
            <el-select v-model="class_value" placeholder="请选择班级">
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="sousuo cursor-pointer" @click="shousuo">搜索</div>
        </div>
      </div>
      <ul v-if="photoShow" class="class_list dis-flex">
        <li class="class_item cursor-pointer"
            v-for="(item,index) in photo" :key="index">
          <div @click="toworks(item)" class="item_img">
            <img :src="item.cover_img" alt="">
          </div>
          <h3>{{ item.title }}</h3>
          <div class="dis-flex space-between">
            <p>
           <span style="color: #000000;font-size: 16px;
           font-weight: 400">{{ item.picnum }}</span>&nbsp张照片/<span style="color:#000000">{{ item.classname }}</span>
            </p>
            <div class="upzp" @click="upzuoping(item)">上传照片</div>
          </div>
        </li>

      </ul>
      <div v-else class="wushuju">
        <img src="../../assets/images/wushuju.png" alt="">
        <!--      <p class="wushuju-text">暂无数据</p>-->
      </div>
      <!---------------表单分页------------------------->
      <div class="block clear" style="text-align: center;margin:30px 20px 0 0">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            :total="pageCounts"
            layout="prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/service/api/api'
import secnav from '@/components/secnav/secnav'

export default {
  components: { secnav },
  name: "course",
  data() {
    return {
      photo: [
        {id: '', img: '', name: '2021油画公开课01', text: ''}
      ],
      currentPage: 1,
      pageCounts: 0,
      page_num: 1,
      class_value: '',
      options: [],
      name: '',
      photoShow: false

    }
  },
  created() {
    this.classinit()
    this.worksinit()
  },
  methods: {
    // 相册列表init
    worksinit() {
      let params = {
        class_id: this.class_value
      }
      api.photpinit(params).then(res => {
        if (res.errcode === 0) {
          this.photo = res.data
          if (res.data.length>0) {
            this.photoShow = true
          }else {
            this.photoShow = false
          }
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },
    // 相册初始化接口
    classinit() {
      api.classinit().then(res => {
        if (res.errcode === 0) {
          this.options = res.data
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },
// 分页每页条数
    handleSizeChange(val) {
      // this.page_num = val
      // console.log(val)
      // console.log(`每页 ${val} 条`);

    },
    // 分页当前页
    handleCurrentChange(val) {
      this.page_num = val
      this.currentPage = val
      // console.log(`当前页: ${val}`);
    },
    // 相册详情
    toworks(item) {
      this.$router.push({
        path: '/photodetali',
        query: {
          classmember_id: item.classmember_id,
          title: item.title,
          album_id: item.album_id,

        }
      })
    },
    // 搜索
    shousuo() {
      this.photo = []
      console.log((this.class_value))
      this.worksinit()
      // console.log(this.name)
    },
    // 上传作品跳转
    upzuoping(item) {
      this.$router.push({
        path: '/upphoto',
        query: {


          title: item.title,
          album_id: item.album_id,
          class_id:item.class_id,
          course_id:item.course_id,
        }
      })
    }
  }
}
</script>

<style scoped>
.nav {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #FFF1F1 0%, #FDF9E9 100%);
  opacity: 0.5;
  /*margin-top: -20px;*/
}

.nav_title {
  width: 87px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin-left: 500px;
  background-color: #FFFFFF;
}

.center {
  width: 65%;
  /*width: 80%;*/
  margin: 0 auto;
  min-height: 300px;
}

.class_nav {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #FF8E42;
}

.class_nav span:first-child {
  color: #999999;
}

.course-nav {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin: 20px 0;
  /*background-color: #4A80F7;*/
}

.course-nav-item {
  color: #999999;
  font-size: 16px;
  margin-right: 20px;
}

.sousuo {
  width: 85px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  color: #FFFFFF;
  text-align: center;
  background: #FF8E42;
  border-radius: 4px;
}

.class_list {
  width: 100%;
  display: flex;
  min-height: 500px;
  flex-wrap: wrap;
  margin: 0 auto;
  /*justify-content: space-between;*/
  /*background-color: #F22E2E;*/

}

.class_item {
  width: 270px;
  height: 250px;
  background: #FFFFFF;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 8px;
  /*align-self: ;*/
  margin: 20px 10px;
}


.item_img {
  /*width: 270px;*/
  height: 152px;
}

.item_img > img {
  width: 100%;
  height: 100%;
}

.class_item h3 {
  min-width: 151px;
  height: 17px;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  padding: 5px 0 0 15px;
  margin-bottom: 20px;
}

.class_item p {
  text-align: right;
  margin-left: 14px;
  margin-top: 8px;
  color: #999999;
  font-size: 15px;
}

.upzp {
  width: 80px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 15px;
  margin-top: 5px;
  background: #FF8E42;
  border-radius: 4px;
}

.wushuju {
  width: 235px;
  height: 600px;
  margin: 100px auto;
}

.wushuju > img {
  width: 235px;
  height: 196px;
}


.layout-nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-top: -4px;
}

.nav-list {
  width: 80%;
  height: 60px;
}

.nav-list-l {
  width: 586px;
  /*height: 24px;*/
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: #6094FF;
}

.nav-list-ls {
  width: 86px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  /*margin-top: 5px;*/
  color: #6094FF;
}

.nav-list-ls > img {
  width: 86px;
  height: 24px;
  margin-top: 16px;
}

.head-navbaac {
  width: 84px;
  height: 60px;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  color: #111111;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border-bottom: 2px solid #FF7800;
}

.head-navba {
  width: 84px;
  height: 60px;
  margin: 0 10px;
}

.head {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
}

.head > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.head-name {
  min-width: 42px;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}


</style>